import React, { useRef, useState, KeyboardEvent, useEffect } from "react";
import "./SearchBar.css";
import { IoSearch } from "react-icons/io5";
import { VscSend } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "react-redux";
import { ACTIVATE_SEARCH } from "../../redux/actionTypes/searchActionTypes";
import {
  activateSearch,
  deactivateSearch,
} from "../../redux/actions/searchActions";
import useEventCapture from "../../hooks/useEventCapture";
import { useLocation } from "react-router-dom";
import { GoArrowUpLeft } from "react-icons/go";
import axios from "axios";
import SuggestedQuery from "../SuggestedQuery/SuggestedQuery";

interface SearchBarProps {
  fetchData: Function;
  text: string;
  setText: Function;
  type?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  fetchData,
  type,
  text,
  setText,
}) => {
  const location = useLocation();
  const buttonRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const { isActive } = useSelector((state: RootState) => state.search);
  const dispatch: AppDispatch = useDispatch();
  const { sendEvent } = useEventCapture();
  const { token } = useSelector((state: RootState) => state.user);
  const [suggestedQueries, setSuggestedQueries] = useState<string[]>([]);
  const douncedTimer = useRef<string | null>(null);
  const suggestedQueriesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestedQueriesRef.current &&
        !suggestedQueriesRef.current.contains(event.target as Node)
      ) {
        setSuggestedQueries([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (text.length > 0) {
      dispatch(activateSearch());
    } else {
      dispatch(deactivateSearch());
    }
  }, [text]);

  const onVisible = () => {
    const companyParam = new URLSearchParams(location.search).get("company");
    // const companyParam = searchParams.get("company");
    sendEvent({
      event_type: "searchbar_visible",
      session_id: sessionStorage.getItem("sessionId") || "",
      token: token || "",
      page: "search_page",
      company: companyParam || "",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onVisible();
        }
      },
      { threshold: 0.5 }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  const debounce = (func: Function, delay: number) => {
    return (...args: any[]) => {
      if (douncedTimer.current) {
        clearTimeout(Number(douncedTimer.current));
      }
      douncedTimer.current = setTimeout(() => {
        func(...args);
      }, delay).toString();
    };
  };

  const fetchSuggestedQuery = (query: string) => {
    axios
      .post(
        "https://jif3m5k2oc2i4xcs546jip5mfu0fekqw.lambda-url.us-west-1.on.aws/autocomplete",
        {
          prefix: text,
          company: new URLSearchParams(location.search).get("company"),
        }
      )
      .then((res) => {
        setSuggestedQueries(res.data.completions);
      })
      .catch((err) => {
        console.log("error: " + err);
      });
  };

  const debouncedFetchSuggestedQuery = debounce(fetchSuggestedQuery, 250);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isActive) {
      buttonRef.current?.click();
    }
  };

  const suggestedQueryClick = ({ query }: { query: string }) => {
    setText(query);
    fetchData({ text: query });
    setSuggestedQueries([]);
  };

  return (
    <div className="searchbar-parent-container">
      <div className={`searchbar ${type}`} ref={searchBarRef}>
        <div className="searchbar-input-field">
          <IoSearch className={`search-icon ${type}`} />
          <input
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              if (
                new URLSearchParams(location.search).get("company") ==
                "riskified"
              ) {
                debouncedFetchSuggestedQuery(e.target.value);
              }
            }}
            onKeyPress={handleKeyPress}
            type="text"
            placeholder="Ask me anything"
            className={`search-input ${type}`}
          />
        </div>
        <div
          className={`search-button ${type} ${!isActive && "disabled"}`}
          ref={buttonRef}
          onClick={() => {
            if (isActive) {
              setSuggestedQueries([]);
              fetchData({ text: text });
            }
          }}
        >
          <VscSend />
        </div>
      </div>
      <div className="suggested-queries-container" ref={suggestedQueriesRef}>
        {suggestedQueries.map((item, i) => {
          return (
            <SuggestedQuery key={i} item={item} onClick={suggestedQueryClick} />
          );
        })}
      </div>
    </div>
  );
};

export default SearchBar;
