import React from "react";
import { Route, Routes } from "react-router-dom";
import Litmus from "../pages/Litmus/Litmus";
import Rhombus from "../pages/Rhombus/Rhombus";
import Revvana from "../pages/Revvana/Revvana";
import Samsara from "../pages/Samsara/Samsara";
import Search from "../pages/Search/Search";
import NotFound from "../pages/NotFound/NotFound";
import Acalvio from "../pages/Acalvio/Acalvio";
import Pipeline from "../pages/Pipeline/Pipeline";
import TermsOfUsage from "../pages/TermsOfUsage/TermsOfUsage";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Demo from "../pages/Demo/Demo";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import ProtectedRoute from "./ProtectedRoute";
import RiskifiedMetrics from "../pages/RiskifiedMetrics/RiskifiedMetrics";
import { useScrollToTop } from "../hooks/useScrollToTop";

const AllRoutes = () => {
  useScrollToTop();
  return (
    <>
      {/* <ScrollToTop /> */}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<h1>Welcome...</h1>} />
        <Route path="/terms-of-use" element={<TermsOfUsage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Protected Routes - Admin Only */}
        <Route
          path="/rhombus"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "rhombus_admin", "rhombus_user"]}
            >
              <Rhombus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/acalvio"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "acalvio_admin", "acalvio_user"]}
            >
              <Acalvio />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pipeline"
          element={
            <ProtectedRoute allowedRoles={["webless_admin"]}>
              <Pipeline />
            </ProtectedRoute>
          }
        />

        {/* Protected Routes - Admin and User */}
        <Route
          path="/litmus"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "litmus_admin", "litmus_user"]}
            >
              <Litmus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/revvana"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "revvana_admin", "revvana_user"]}
            >
              <Revvana />
            </ProtectedRoute>
          }
        />
        <Route
          path="/samsara"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "samsara_admin", "samsara_user"]}
            >
              <Samsara />
            </ProtectedRoute>
          }
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute allowedRoles="all">
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="/demo"
          element={
            <ProtectedRoute allowedRoles={["webless_admin", "webless_user"]}>
              <Demo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/riskified-metrics"
          element={
            <ProtectedRoute
              allowedRoles={["webless_admin", "riskified_user", "litmus_user"]}
            >
              <RiskifiedMetrics />
            </ProtectedRoute>
          }
        />

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
