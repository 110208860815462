import React from "react";
import "./SuggestedQuery.css";
import { GoArrowUpLeft } from "react-icons/go";

interface SuggestedQueryProps {
  item: string;
  onClick: Function;
}

const SuggestedQuery: React.FC<SuggestedQueryProps> = ({ item, onClick }) => {
  return (
    <div
      className="suggested-query"
      onClick={() => {
        onClick({ query: item });
      }}
    >
      <p>{item}</p>
      <GoArrowUpLeft />
    </div>
  );
};

export default SuggestedQuery;
